/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { authProvider } from './auth/authProvider';
import registerServiceWorker from './registerServiceWorker';
import Authentication from './pages/Authentication';
import Button from './components/Button';
import AlertBlock from './components/AlertBlock';
import ReactAlert from './components/ReactAlert';
import themes from './config/theme';
import GlobalStyle from './GlobalStyle';

const Root = () => (
  <ThemeProvider theme={themes.base}>
    <BrowserRouter>
      <GlobalStyle />
      <AzureAD provider={authProvider} forceLogin>
        {({ login, authenticationState, error }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return (
                <ReactAlert>
                  <App authProvider={authProvider} />
                </ReactAlert>
              );
            case AuthenticationState.Unauthenticated:
              return (
                <Authentication>
                  {error && (
                    <AlertBlock danger icon="danger">
                      Wystąpił błąd podczas uwierzytelniania. Twój token stracił
                      ważność!
                    </AlertBlock>
                  )}
                  <Button primary onClick={login} text="Zaloguj ponownie" />
                </Authentication>
              );
            case AuthenticationState.InProgress:
              return <Authentication>Trwa uwierzytelnianie...</Authentication>;
            default:
              return false;
          }
        }}
      </AzureAD>
    </BrowserRouter>
  </ThemeProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
registerServiceWorker();
