import momentTimezone from 'moment-timezone';
import api from './init';
import { getCurrentToken } from '../helpers/users';

export function attendanceList(data) {
  return api
    .get('/homeoffice', {
      params: data,
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data);
}

const dateUTC = dataArray =>
  momentTimezone(dataArray)
    .tz('Europe/Warsaw')
    .toDate()
    .setHours(12, 0, 0, 0);

export function reportAttendance(currentUser, startPresence) {
  return api.put(
    `/homeoffice`,
    {
      user: currentUser.id,
      name: currentUser.name,
      email: currentUser.email,
      businessUnit: currentUser.department,
      startDate: dateUTC(startPresence)
    },
    {
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    }
  );
}

export function deleteAttendance(id) {
  return api
    .delete(`/homeoffice/${id}`, {
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res);
}
