import api from './init';
import { getCurrentToken } from '../helpers/users';

export function getAccessToken(data) {
  const params = data;
  params.date = new Date();

  return api
    .get('/token', {
      params
    })
    .then(res => res.data)
    .catch(res => {
      throw res.response.data;
    });
}

export function getAccessPermissions() {
  return api
    .get('/users/permissions', {
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data)
    .catch(res => {
      throw res.response.data;
    });
}
