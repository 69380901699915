import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import 'moment/locale/pl';
import { formatTime } from '../../../helpers/bookingForm';
import { bookingFilter, currentFloorByUser } from '../../../helpers/booking';
import { roles } from '../../../config/roles';
import { messages } from '../../../helpers/forms';
import BookingForm from './BookingForm';
import { makeBooking, updateBookingStateOnMake } from '../../../api/booking';
import { sendMassage } from '../../../api/mailer';
import { addEvent } from '../../../api/calendar';
import Spinner from '../../../components/Spinner';

const BookingFormWrapper = ({
  setUserBookings,
  userBookings,
  currentUser,
  currentRoom,
  currentHour,
  currentDataDate,
  bookingData,
  calendarDate,
  userRoles
}) => {
  const alert = useAlert();
  const history = useHistory();
  const [formDisabled, setFormDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const forGuests = currentRoom.assets.includes('guest');

  const admins = [];

  userRoles.forEach(role => {
    if (role.permission >= roles.admin) admins.push(role.email);
  });

  useEffect(() => {
    if (
      parseInt(currentRoom.floor, 10) === 3 &&
      !forGuests &&
      currentUser.role < roles.ceo
    ) {
      const currentDate = new Date(currentDataDate);
      const currentDay = currentDate.getDay();
      let daysBefore = 1;

      switch (true) {
        case currentUser.role >= roles.director:
          daysBefore = 14;
          break;
        case currentDay === 4 || currentDay === 5:
          daysBefore = 3;
          break;
        case currentDate.getHours() >= 17:
          daysBefore = 2;
          break;
        default:
          daysBefore = 1;
      }

      const daysBeforeCalendar = new Date(calendarDate);
      daysBeforeCalendar.setDate(daysBeforeCalendar.getDate() - daysBefore);

      if (daysBeforeCalendar > currentDate) {
        setFormDisabled(true);
        alert.info(
          `Możliwość rezerwacji max ${daysBefore} dzień/dni do przodu.`,
          {
            timeout: 0
          }
        );
      } else {
        setFormDisabled(false);
        alert.removeAll();
      }
    }
  }, [calendarDate]);

  useEffect(() => {
    if (forGuests && currentUser.role < roles.director)
      alert.info(
        'Miejsce dla gości. Rezerwacja wymaga potwierdzenia przez administratora.',
        { timeout: 0 }
      );
  }, []);

  const handleEndDate = dateArray => {
    const recurringEndDate = [];
    dateArray.forEach(item => recurringEndDate.push(parseFloat(item)));
    return recurringEndDate;
  };

  // Format the recurring data into an array
  const handleRecurringData = (type, date) => {
    let recurringData = [];
    if (type !== 'none') {
      recurringData = [date, type];
      recurringData[0][1] -= 1;
    } else {
      recurringData = [];
    }
    return recurringData;
  };

  const convertDate = (dateArray, date) => {
    const temp = formatTime(date);
    return [...dateArray, ...temp];
  };

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    const { href } = window.location;
    const dateArray = moment(calendarDate)
      .format('Y M D')
      .split(' ')
      .map(item => parseInt(item, 10));
    dateArray[1] -= 1;
    const roomId = currentRoom._id;
    const startDate = convertDate(dateArray, values.startTime);
    const endDate = convertDate(dateArray, values.endTime);
    const recurringType = values.recurring;
    let responseMessage;
    const {
      purpose,
      description,
      presence,
      attendees,
      recurringEndDate
    } = values;
    let recurringData = [];

    if (recurringEndDate !== '') {
      const recurringEnd = handleEndDate(recurringEndDate.split('-'));
      recurringData = handleRecurringData(recurringType, recurringEnd);
    }

    const existingBookings = bookingFilter(bookingData, roomId);
    const myBookedByCurrentFloor =
      parseInt(currentRoom.floor, 10) !== 1
        ? currentFloorByUser(bookingData, currentRoom.floor, currentUser.id)
        : [];

    const afterFormSendSuccess = (timeout = 0) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm({});
        setLoading(false);
        history.push('/');
      }, timeout);
    };

    // Check if there is a clash and, if not, save the new booking to the database
    try {
      setLoading(true);
      let booking;

      // if its azure active directory id book only in outlook calendar
      if (currentRoom.aadId) {
        addEvent(
          currentUser,
          calendarDate,
          startDate,
          endDate,
          currentRoom,
          `${purpose} ${description && ` - ${description}`}`,
          recurringType,
          recurringEndDate,
          attendees,
          presence
        )
          .then(() => {
            alert.success(`Salka ${currentRoom.name} zarezerwowana.`);
            afterFormSendSuccess(2000);
          })
          .catch(err => {
            setLoading(false);
            alert.error(err.message);
          });
      } else {
        await makeBooking(
          {
            currentUser,
            startDate,
            endDate,
            purpose,
            currentRoom,
            description,
            recurringData,
            attendees,
            presence
          },
          existingBookings,
          myBookedByCurrentFloor
        )
          .then(response => {
            booking = [].concat(response.data || []); // force to be array
          })
          .catch(err => {
            setLoading(false);

            throw new Error(
              err.response.data.error.message.match(/error:.+/i)[0]
            );
          });

        // If the new booking is successfully saved to the database
        await updateBookingStateOnMake(
          setUserBookings,
          userBookings,
          booking,
          currentUser,
          currentRoom
        );

        switch (parseInt(currentRoom.floor, 10)) {
          case 2:
            responseMessage = `${currentRoom.name} zarezerwowany.`;
            break;
          default:
            responseMessage = `${currentRoom.name} zarezerwowane.`;
        }

        if (forGuests && currentUser.role < roles.ceo) {
          alert.info(
            `${currentRoom.name} oczekuje na potwierdzenie przez administratora.`
          );

          sendMassage(
            `Nowa rezerwacja oczekująca.`,
            `W systemie pojawiła się nowa oczekująca rezerwacja miejsca garażowego dla gości. <br /><br />Osoba: <b>${
              currentUser.name
            }</b><br />Data: <b>${moment(calendarDate).format('DD-MM-Y')} ${
              values.startTime
            } - ${
              values.endTime
            }</b><br />Opis: ${description}<br /><br /><a href="${
              process.env.REACT_APP_URL
            }/pendingbookings/${
              booking[0]._id
            }/3">Zaakceptuj</a> lub <a href="${
              process.env.REACT_APP_URL
            }/pendingbookings/${booking[0]._id}/2">Odrzuć</a>`,
            !(href.includes('localhost') || href.includes('beta'))
              ? admins.join(', ')
              : `andrzej.wilczynski@plej.pl`
          );
        } else if (parseInt(currentRoom.floor, 10) === 3) {
          alert.success(responseMessage);
          sendMassage(
            `Zarezerwowano '${currentRoom.name}'`,
            `Zaparkuj na '${
              currentRoom.name
            }'. <br />Rezerwacja jest ważna w dniu ${moment(
              calendarDate
            ).format(
              'Y-MM-DD'
            )}. <br />Jeśli postanowisz z niego nie korzystać pamiętaj aby odwołać rezerwację.`,
            currentUser.email
          );
        } else {
          alert.success(responseMessage);
        }

        afterFormSendSuccess();
      }
    } catch (e) {
      alert.error(e.message);
    }
  };

  // TODO - spinner styles, add background
  return (
    <>
      {loading && <Spinner />}
      <Formik
        initialValues={{
          startTime: currentHour || '',
          endTime: '',
          recurring: 'none',
          recurringEndDate: '',
          purpose: parseInt(currentRoom.floor, 10) !== 1 ? 'n.d.' : '',
          description: '',
          business: currentUser.unit,
          attendees: [],
          presence: parseInt(currentRoom.floor, 10) !== 1 && !forGuests
        }}
        validationSchema={Yup.object({
          startTime: Yup.string().required(messages.required),
          endTime: Yup.string().required(messages.required),
          purpose: Yup.string().required(messages.required),
          recurring: Yup.string().required(messages.required),
          recurringEndDate: Yup.string().when('recurring', {
            is: val => val !== 'none',
            then: Yup.string().required(messages.required)
          }),
          attendees: Yup.array().of(
            Yup.string()
              .email(messages.email)
              .required(messages.required)
          )
        })}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          handleSubmit(values, setSubmitting, resetForm)
        }
      >
        {({ values, errors, touched, setFieldValue }) => (
          <BookingForm
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            formDisabled={formDisabled}
            calendarDate={calendarDate}
            currentDataDate={currentDataDate}
            currentRoom={currentRoom}
          />
        )}
      </Formik>
    </>
  );
};

export default BookingFormWrapper;
