import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './GarageView.styles';
import BookingTooltip from '../BookingTooltip';
import Icon from '../../../../components/Icon/Icon';
import { roles } from '../../../../config/roles';

const sortByNumber = (a, b) => {
  let nameA;
  let nameB;
  const regExp = /^\D+/g;

  if (typeof a.name === 'string') {
    nameA = a.name.replace(regExp, '');
  } else {
    nameA = a.name;
  }

  if (typeof b.name === 'string') {
    nameB = b.name.replace(regExp, '');
  } else {
    nameB = b.name;
  }

  return nameA - nameB;
};

const GarageView = props => {
  const {
    rooms,
    onSetRoom,
    bookings,
    setSelectedBooking,
    calendarDate,
    setCurrentHour,
    currentUser
  } = props;

  const staticPlaces = [
    { name: 66, static: true },
    { name: 69, static: true },
    { name: 74, static: true },
    { name: 75, static: true }
  ];

  const allPlaces = rooms.concat(staticPlaces);
  allPlaces.sort(sortByNumber);

  return (
    <Styled.GarageView>
      <div className="floor-plan garage">
        <ul className="park-spaces-group top-group">
          {allPlaces.map(place => (
            <li className="park-space-wrapper" key={place.name}>
              {(place.static === true ||
                (place.name.includes('nr 67') &&
                  currentUser.role < roles.director)) && (
                <span className="park-space-content unavailable">
                  <span className="park-space">
                    <span className="park-number">
                      {String(place.name).match(/\d/g)}
                    </span>
                    <span className="park-state">niedostępne dla Plej</span>
                  </span>
                </span>
              )}
              {!place.static &&
                ((place.name.includes('nr 67') &&
                  currentUser.role >= roles.director) ||
                  !place.name.includes('nr 67')) && (
                  <div
                    className={`park-space-content plej-space ${
                      place.assets.includes('guest') ? 'guest-space' : ''
                    }`}
                  >
                    <Link
                      to="/createbooking"
                      onClick={() => onSetRoom(place._id)}
                      className="park-space"
                    >
                      {place.assets.includes('guest') ? (
                        <Icon icon="guest" size={30} className="guest-icon" />
                      ) : (
                        <img
                          src="./garage/logo.png"
                          className="logo-plej"
                          alt="Plej"
                        />
                      )}
                      <span className="park-number">
                        {place.name.match(/\d/g)}
                      </span>
                      <span className="park-state">miejsce Plej</span>
                    </Link>
                    <BookingTooltip
                      room={place}
                      bookings={bookings}
                      setSelectedBooking={setSelectedBooking}
                      calendarDate={calendarDate}
                      onSetRoom={onSetRoom}
                      setCurrentHour={setCurrentHour}
                      currentUser={currentUser}
                    />
                  </div>
                )}
            </li>
          ))}
        </ul>
        <div className="way">
          <span className="exit">
            <span className="exit-content">Poziom -1</span>
          </span>
        </div>
        <div className="boxes">
          <span className="stairs">Do klatki schodowej</span>
        </div>
      </div>
    </Styled.GarageView>
  );
};

export default GarageView;
