import React from 'react';
import 'moment/locale/pl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Button from '../../../components/Button';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { messages } from '../../../helpers/forms';
import { listGaragesReport } from '../../../api/booking';

const ReportsForm = ({ setFetchedData }) => {
  const alert = useAlert();

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const { fromDate } = values;
    const toDate = new Date(values.toDate);
    toDate.setHours(23);

    // Check if there is a clash and, if not, save the new booking to the database
    try {
      listGaragesReport({ fromDate, toDate })
        .then(response => {
          setFetchedData(response.data);

          setSubmitting(false);
          resetForm({});
        })
        .catch(err => {
          alert.info(err.response.data.error.message.match(/error:.+/i)[0]);
        });
    } catch (err) {
      alert.error(err);
    }
  };

  return (
    <Formik
      initialValues={{
        fromDate: '',
        toDate: ''
      }}
      validationSchema={Yup.object({
        fromDate: Yup.string().required(messages.required),
        toDate: Yup.string().required(messages.required)
      })}
      onSubmit={(values, { setSubmitting, resetForm }) =>
        handleSubmit(values, setSubmitting, resetForm)
      }
    >
      {({ values, errors, touched }) => (
        <Form noValidate>
          <CustomDatePicker
            name="fromDate"
            type="text"
            label="Obecności od"
            error={errors.fromDate}
            touched={touched.fromDate}
            inputValue={values.fromDate}
            dateFormat="yyyy-MM-dd"
            popperPlacement="top-start"
          />
          <CustomDatePicker
            name="toDate"
            type="text"
            label="Obecności do"
            error={errors.toDate}
            touched={touched.toDate}
            inputValue={values.fromDate}
            dateFormat="yyyy-MM-dd"
            popperPlacement="top-start"
          />
          <div className="text-right">
            <Button primary large text="Wygeneruj raport" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReportsForm;
