import React from 'react';
import { roles } from '../../config/roles';
import * as Styled from './Navigation.styles';

const Navigation = ({ currentUser, navToggler }) => (
  <Styled.Navigation
    className={`${navToggler.navOpened ? 'nav-expanded' : ''}`}
  >
    <Styled.NavUserBox>
      <Styled.NavUserIcon icon="user-rounded" size={32} />
      <Styled.NavUserText>
        <span>{currentUser.name}</span>
        <small>Użytkownik zalogowany</small>
      </Styled.NavUserText>
    </Styled.NavUserBox>
    <Styled.NavList>
      <Styled.NavItem>
        <Styled.NavLink to="/" exact>
          <Styled.NavIcon icon="frequency" removeInlineStyle />
          Rezerwuj zasoby
        </Styled.NavLink>
      </Styled.NavItem>
      <Styled.NavItem>
        <Styled.NavLink to="/mybookings">
          <Styled.NavIcon icon="booking" removeInlineStyle />
          Zarezerwowane zasoby
        </Styled.NavLink>
      </Styled.NavItem>
      <Styled.NavItem>
        <Styled.NavLink to="/attendance-list">
          <Styled.NavIcon icon="attendance" removeInlineStyle />
          Zgłoszenia obecności
        </Styled.NavLink>
      </Styled.NavItem>
      <Styled.NavItem>
        <Styled.NavLink to="/home-office">
          <Styled.NavIcon icon="attendance" removeInlineStyle />
          Home office
        </Styled.NavLink>
      </Styled.NavItem>
      {currentUser && currentUser.role === roles.admin && (
        <>
          <Styled.NavItem>
            <Styled.NavLink to="/pendingbookings">
              <Styled.NavIcon icon="pending" removeInlineStyle />
              Rezerwacje oczekujące
            </Styled.NavLink>
          </Styled.NavItem>
          <Styled.NavItem>
            <Styled.NavLink to="/reports">
              <Styled.NavIcon icon="availability" removeInlineStyle />
              Raport obecności w biurze
            </Styled.NavLink>
          </Styled.NavItem>
          <Styled.NavItem>
            <Styled.NavLink to="/homeoffice-reports">
              <Styled.NavIcon icon="availability" removeInlineStyle />
              Raport home office
            </Styled.NavLink>
          </Styled.NavItem>
          <Styled.NavItem>
            <Styled.NavLink to="/garage-reports">
              <Styled.NavIcon icon="availability" removeInlineStyle />
              Raport miejsc garażowych
            </Styled.NavLink>
          </Styled.NavItem>
        </>
      )}
      {currentUser && (
        <Styled.NavItem>
          <Styled.NavLink to="/account">
            <Styled.NavIcon icon="user" removeInlineStyle />
            Twoje konto
          </Styled.NavLink>
        </Styled.NavItem>
      )}
    </Styled.NavList>
    <Styled.NavLogoutBtn onClick={currentUser.logout} asLink>
      <Styled.NavIcon icon="unlock" removeInlineStyle />
      Wyloguj się
    </Styled.NavLogoutBtn>
  </Styled.Navigation>
);

export default Navigation;
