import React from 'react';
import { useAlert } from 'react-alert';
import Table from '../../../components/Typography/Table';
import * as Styled from '../../../components/Typography/Table/Table.styles';
import Button from '../../../components/Button';
import { deleteAttendance } from '../../../api/homeOffice';
import { roles } from '../../../config/roles';

const momentTimezone = require('moment-timezone');

const AttendanceTable = props => {
  const {
    homeOfficeData,
    setHomeOfficeData,
    currentUser,
    filteredData
  } = props;
  const alert = useAlert();

  const onRemoveAttendance = id => {
    alert.info(`Na pewno chcesz usunąć home office?`, {
      customElements: () => (
        <Button
          onClick={() => {
            deleteAttendance(id).then(() => {
              const newAttendanceData = homeOfficeData.filter(
                attendance => attendance._id !== id
              );
              setHomeOfficeData(newAttendanceData);
            });
            alert.removeAll();
          }}
          alert
        >
          Usuń
        </Button>
      )
    });
  };

  return (
    <Styled.TableWrapper>
      <Table>
        <thead>
          <tr>
            <th>Użytkownik</th>
            <th>Data pobytu</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(row => {
            const startDate = new Date(row.startDate);
            startDate.setHours(17);

            return (
              <tr
                key={row._id}
                className={`${
                  currentUser.id === row.user ? 'my-attendance' : ''
                }`}
              >
                <td>{row.name}</td>
                <td className="attendance-action">
                  <span className="text-left">
                    {momentTimezone(row.startDate)
                      .tz('Europe/Warsaw')
                      .format('DD.MM.Y')}
                  </span>
                  {/* jesli admin, pol godizny po dodaniu, albo do 17 tego samego dnia */}
                  {(currentUser.role === roles.admin ||
                    currentUser.id === row.user) && (
                    <span className="text-right">
                      <Button
                        remove
                        onlyIcon
                        text="Usuń home office"
                        onClick={() => onRemoveAttendance(row._id)}
                      />
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Styled.TableWrapper>
  );
};

export default AttendanceTable;
