import React from 'react';
import Table from '../../../components/Typography/Table';
import * as Styled from '../../../components/Typography/Table/Table.styles';
import * as StyledAttendance from './AttendanceTable.styles';

const momentTimezone = require('moment-timezone');

const AttendanceTable = props => {
  const { fetchedData } = props;

  return (
    <Styled.TableWrapper>
      <Table id="home-office">
        <thead>
          <tr>
            <th>Pracownik</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {fetchedData.map(row => (
            <tr key={row._id}>
              <td>{row.name}</td>
              <td>
                {momentTimezone(row.startDate)
                  .tz('Europe/Warsaw')
                  .format('DD.MM.Y')}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <StyledAttendance.ExcelButton
        className="download-table-xls-button"
        table="home-office"
        filename="raport-home-office"
        sheet="ho"
        buttonText="Pobierz jako XLS"
        primary
      />
    </Styled.TableWrapper>
  );
};

export default AttendanceTable;
