import React from 'react';
import { useAlert } from 'react-alert';
import Table from '../../../components/Typography/Table';
import * as Styled from '../../../components/Typography/Table/Table.styles';
import Button from '../../../components/Button';
import { deleteAttendance } from '../../../api/presence';
import { roles } from '../../../config/roles';

const momentTimezone = require('moment-timezone');

const AttendanceTable = props => {
  const {
    attendanceData,
    setAttendanceData,
    setBookingData,
    setUserBookings,
    currentAttendanceDate,
    currentUser,
    userBookings,
    bookingData,
    filteredData
  } = props;
  const alert = useAlert();

  const onRemoveAttendance = (id, data) => {
    alert.info(
      `Na pewno chcesz usunąć swoją obecność? \nPotwierdzenie spowoduje usunięcie Twoich powiązanych z obecnością rezerwacji zasobów.`,
      {
        customElements: () => (
          <Button
            onClick={() => {
              deleteAttendance(id, data).then(response => {
                if (Array.isArray(response.data)) {
                  // array of deleted bookings here -> now we can filter them and delete from current state (if already set)
                  if (bookingData) {
                    const newBookingData = bookingData.filter(
                      booking => !response.data.includes(booking._id)
                    );
                    setBookingData(newBookingData);
                  }

                  if (userBookings) {
                    const newUserBookings = { ...userBookings };
                    newUserBookings.current = userBookings.current.filter(
                      booking => !response.data.includes(booking._id)
                    );
                    setUserBookings(newUserBookings);
                  }
                }

                const newAttendanceData = attendanceData.filter(
                  attendance => attendance._id !== id
                );
                setAttendanceData(newAttendanceData);
              });
              alert.removeAll();
            }}
            alert
          >
            Usuń
          </Button>
        )
      }
    );
  };

  return (
    <Styled.TableWrapper>
      <Table>
        <thead>
          <tr>
            <th>Użytkownik</th>
            <th>Data pobytu</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(row => {
            const startDate = new Date(row.startDate);
            startDate.setHours(17);
            const now = new Date(currentAttendanceDate);

            return (
              <tr
                key={row._id}
                className={`${
                  currentUser.id === row.user ? 'my-attendance' : ''
                }`}
              >
                <td>{row.name}</td>
                <td className="attendance-action">
                  <span className="text-left">
                    {momentTimezone(row.startDate)
                      .tz('Europe/Warsaw')
                      .format('DD.MM.Y')}
                  </span>
                  {/* jesli admin, pol godizny po dodaniu, albo do 17 tego samego dnia */}
                  {(currentUser.role === roles.admin ||
                    (currentUser.id === row.user && now < startDate)) && (
                    <span className="text-right">
                      <Button
                        remove
                        onlyIcon
                        text="Usuń obecność"
                        onClick={() =>
                          onRemoveAttendance(row._id, {
                            user: row.user,
                            currentUser,
                            startDate: row.startDate
                          })
                        }
                      />
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Styled.TableWrapper>
  );
};

export default AttendanceTable;
