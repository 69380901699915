import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Layout from '../../layouts/Layout/Layout';
import Content from '../../layouts/Content';
import Sidebar from '../../layouts/Sidebar';
import * as Styled from './HomeOffice.styles';
import 'moment/locale/pl';
import Calendar from '../../components/Calendar';
import TableContentLoader from '../../components/ContentLoader/TableContentLoader';
import AttendanceForm from './AttendanceForm';
import AttendanceTable from './AttendanceTable';
import Box from '../../layouts/Box';
import { attendanceList } from '../../api/homeOffice';
import { filterAttendanceByDate } from '../../helpers/presence';
import { roles } from '../../config/roles';

const HomeOffice = props => {
  const {
    calendarDate,
    setCalendarDate,
    currentUser,
    navToggler,
    currentHomeOfficeDate,
    setCurrentHomeOfficeDate,
    homeOfficeData,
    setHomeOfficeData
  } = props;
  const [filteredData, setFilteredData] = useState([]);

  const loadMissingData = () => {
    attendanceList({
      fromDate: currentHomeOfficeDate,
      user: currentUser.role === roles.admin ? undefined : currentUser.id
    })
      .then(response => {
        setCurrentHomeOfficeDate(response.currentDate);

        if (response.data)
          setHomeOfficeData([...homeOfficeData].concat(response.data));
      })
      .catch(error => console.error('Error loading booking data', error));
  };

  // load all necessary data
  useEffect(() => {
    if (homeOfficeData.length === 0) {
      attendanceList({
        user: currentUser.role === roles.admin ? undefined : currentUser.id
      })
        .then(response => {
          setHomeOfficeData(response.data);
          setCurrentHomeOfficeDate(response.currentDate);
        })
        .catch(error => console.error('Error loading booking data', error));
    } else {
      // load missing data
      loadMissingData();
    }
  }, []);

  useEffect(() => {
    const filteredByDate = filterAttendanceByDate(homeOfficeData, calendarDate);

    setFilteredData(filteredByDate);
    // setFilteredData(filteredByDate => filteredByDate);
  }, [calendarDate, homeOfficeData]);

  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title={`Zgłaszanie home office (${moment(calendarDate).format(
        'D MMMM YYYY'
      )})`}
    >
      <Sidebar className="sidebar-sticky">
        <Calendar
          calendarDate={calendarDate}
          setCalendarDate={setCalendarDate}
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          input
          value={moment(calendarDate)}
          renderInput={() => (
            <div className="calendar-aside">
              {moment(calendarDate).format('dddd, [\n] LL')}
            </div>
          )}
          utc={false}
          locale="pl"
          onChange={event => setCalendarDate(event._d)}
        />
      </Sidebar>
      <Content>
        <Styled.AttendanceList>
          <Box header="Zgłoś home office">
            <AttendanceForm
              currentUser={currentUser}
              calendarDate={moment(calendarDate)}
              loadMissingData={loadMissingData}
            />
          </Box>

          <Box header="Lista home office">
            {homeOfficeData.length === 0 && <TableContentLoader />}
            {filteredData.length !== 0 ? (
              <AttendanceTable
                currentUser={currentUser}
                currentHomeOfficeDate={currentHomeOfficeDate}
                homeOfficeData={homeOfficeData}
                filteredData={filteredData}
                setHomeOfficeData={setHomeOfficeData}
              />
            ) : (
              homeOfficeData.length !== 0 && (
                <p>Brak zgłoszonych home office na ten dzień.</p>
              )
            )}
          </Box>
        </Styled.AttendanceList>
      </Content>
    </Layout>
  );
};

export default HomeOffice;
