import React from 'react';
import 'moment/locale/pl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Button from '../../../components/Button';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { messages } from '../../../helpers/forms';
import { reportAttendance } from '../../../api/homeOffice';

const AttendanceForm = ({ currentUser, calendarDate, loadMissingData }) => {
  const alert = useAlert();

  const handleSubmit = (values, setSubmitting, resetForm) => {
    try {
      reportAttendance(currentUser, values.startPresence, values.endPresence)
        .then(() => {
          loadMissingData();

          setSubmitting(false);
          resetForm({});
          alert.success('Zgłoszono home office.');
        })
        .catch(err => {
          loadMissingData();
          const errorMessage = err.response.data.error.message.match(
            /error:.+/i
          );
          alert.info(
            errorMessage ? errorMessage[0] : err.response.data.error.message
          );
        });
    } catch (e) {
      alert.error(e.message);
    }
  };

  return (
    <Formik
      initialValues={{
        startPresence: calendarDate
      }}
      enableReinitialize
      validationSchema={Yup.object({
        startPresence: Yup.string().required(messages.required)
      })}
      onSubmit={(values, { setSubmitting, resetForm }) =>
        handleSubmit(values, setSubmitting, resetForm)
      }
    >
      {({ values, errors, touched }) => (
        <Form noValidate>
          <CustomDatePicker
            name="startPresence"
            type="text"
            label="Obecność dnia"
            error={errors.startPresence}
            touched={touched.startPresence}
            inputValue={values.startPresence}
            dateFormat="yyyy-MM-dd"
            popperPlacement="top-start"
            disabled
          />
          <div className="text-right">
            <Button primary large text="Zgłoś home office" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AttendanceForm;
