import momentTimezone from 'moment-timezone';
import api from './init';
import { getCurrentToken } from '../helpers/users';

export function listBookings(data) {
  return api
    .get('/bookings', {
      params: data,
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data);
}

export function listGaragesReport(data) {
  return api
    .get('/bookings/garages', {
      params: data,
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data);
}

export function getBooking(id) {
  return api
    .get(`/booking/${id}`, {
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data);
}

const dateUTC = dataArray =>
  momentTimezone(dataArray)
    .tz('Europe/Warsaw')
    .toDate();

const checkClash = (existingBookings, newBookingStart, newBookingEnd) => {
  const response = [];

  existingBookings.forEach((booking, key) => {
    if (booking.status === 2) return; // check if its not rejected booking

    // Convert existing booking Date objects into number values
    const existingBookingStart = new Date(booking.bookingStart).getTime();
    const existingBookingEnd = new Date(booking.bookingEnd).getTime();

    // Check whether there is a clash between the new booking and the existing booking
    if (
      (newBookingStart >= existingBookingStart &&
        newBookingStart < existingBookingEnd) ||
      (existingBookingStart >= newBookingStart &&
        existingBookingStart < newBookingEnd)
    )
      response.push(
        `${momentTimezone
          .tz(existingBookings[key].bookingStart, 'Europe/Warsaw')
          .format('dddd, D MMMM YYYY, H:mm')}-${momentTimezone
          .tz(existingBookings[key].bookingEnd, 'Europe/Warsaw')
          .format('H:mm')}`
      );
  });

  return response;
};

// Make a room booking
export function makeBooking(data, existingBookings, myBookedByCurrentFloor) {
  // Convert booking data to UTC Date objects
  const bookingStart = dateUTC(data.startDate);
  const bookingEnd = dateUTC(data.endDate);
  const currentDate = new Date();
  const forGuests = data.currentRoom.assets.includes('guest');
  const { presence } = data;
  let status = 3;
  let clash = [];
  let currentFloorClash = [];
  let bookingsInRange;
  let units;

  // Convert booking Date objects into a number value
  const newBookingStart = bookingStart.getTime();
  const newBookingEnd = bookingEnd.getTime();

  if (newBookingStart > newBookingEnd)
    throw new Error(
      'Data zakończenia musi być późniejsza niż data rozpoczęcia.'
    );

  if (newBookingStart < currentDate.getTime())
    throw new Error('Data rozpoczęcia musi być datą w przyszłości.');

  if (data.recurringData.length) {
    // If a recurring booking as been selected, ensure the end date is after the start date
    if (dateUTC(data.recurringData[0]).getTime() <= newBookingEnd)
      throw new Error("Błędna data dla 'Powtórz do'.");

    const bookingDateTracker = momentTimezone(data.startDate);
    const bookingDateTrackerEnd = momentTimezone(data.endDate);

    const lastBookingDate = momentTimezone(data.recurringData[0]);
    lastBookingDate.hour(bookingDateTracker.hour() + 1);

    if (data.recurringData[1] === 'daily') {
      bookingsInRange = Math.floor(
        lastBookingDate.diff(bookingDateTracker, 'days', true)
      );
      units = 'd';
    } else if (data.recurringData[1] === 'weekly') {
      bookingsInRange = Math.floor(
        lastBookingDate.diff(bookingDateTracker, 'weeks', true)
      );
      units = 'w';
    } else {
      bookingsInRange = Math.floor(
        lastBookingDate.diff(bookingDateTracker, 'months', true)
      );
      units = 'M';
    }

    for (let i = 0; i <= bookingsInRange; i += 1) {
      if (data.currentUser.role < 90) {
        const c = checkClash(
          existingBookings,
          bookingDateTracker,
          bookingDateTrackerEnd
        );
        clash = c.length ? clash.concat(c) : clash;
      }

      if (
        data.currentUser.role < 90 &&
        parseInt(data.currentRoom.floor, 10) !== 1
      ) {
        const hc = checkClash(
          myBookedByCurrentFloor,
          bookingDateTracker,
          bookingDateTrackerEnd
        );
        currentFloorClash = hc.length
          ? currentFloorClash.concat(hc)
          : currentFloorClash;
      }

      bookingDateTracker.add(1, units);
      bookingDateTrackerEnd.add(1, units);
    }
  } else {
    // if no recurring - check for booking clash for all existing bookings
    if (data.currentUser.role < 90)
      clash = checkClash(existingBookings, newBookingStart, newBookingEnd);

    // data.currentUser.role < 90

    // if garage or hotdesks
    if (
      data.currentUser.role < 90 &&
      parseInt(data.currentRoom.floor, 10) !== 1
    )
      currentFloorClash = checkClash(
        myBookedByCurrentFloor,
        newBookingStart,
        newBookingEnd
      );
  }

  if (clash.length)
    throw new Error(
      `Błąd rezerwacjj. Wystąpiła kolizja z rezerwacj${
        clash.length === 1 ? 'ą' : 'ami'
      }: \n\n • ${clash.join('\n• ')}`
    );

  // dont allow to book another hotdesk or garage (unless its for guests)
  if (currentFloorClash.length && !forGuests)
    throw new Error(
      `Błąd rezerwacjj. Masz już zarezerwowan${
        parseInt(data.currentRoom.floor, 10) === 2
          ? 'y inny hotdesk'
          : 'e inne miejsce garażowe'
      } w termin${
        currentFloorClash.length === 1 ? 'ie' : 'ach'
      }: ${currentFloorClash.join('; ')}`
    );

  if (forGuests && data.currentUser.role < 80) status = 1; // pending

  // Save the booking to the database and return the booking
  return api.put(
    `/bookings`,
    {
      user: data.currentUser.id,
      name: data.currentUser.name,
      email: data.currentUser.email,
      businessUnit: data.currentUser.department,
      bookingStart,
      bookingEnd,
      purpose: data.purpose,
      roomId: data.currentRoom._id,
      roomName: data.currentRoom.name,
      description: data.description,
      recurring: data.recurringData,
      attendees: data.attendees || [],
      status,
      presence
    },
    { headers: { Authorization: `Bearer ${getCurrentToken()}` } }
  );
}

// Delete a room booking
export function deleteBooking(bookingId, data) {
  return api
    .delete(`/bookings/${bookingId}`, {
      data,
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data);
}

// Update booking status
export function updateBookingStatus(bookingId, status) {
  return api
    .put(
      `/booking/${bookingId}/status/${status}`,
      {},
      { headers: { Authorization: `Bearer ${getCurrentToken()}` } }
    )
    .then(res => res);
}

export function updateBookingStateOnDelete(
  setBookingData,
  bookingData,
  setUserBookings,
  userBookings,
  bookingId
) {
  // Find the relevant room in React State and replace it with the new room data
  if (bookingData) {
    const updatedBookingData = bookingData.filter(booking => {
      return booking._id !== bookingId;
    });

    setBookingData(updatedBookingData);
    setBookingData(bookings => bookings);
  }

  if (userBookings) {
    const updatedUserBookings = { ...userBookings };

    updatedUserBookings.current = updatedUserBookings.current.filter(
      booking => {
        return booking._id !== bookingId;
      }
    );

    setUserBookings(updatedUserBookings);
  }
}

export function updateBookingStateOnUpdate(
  setBookingData,
  bookingData,
  bookingId,
  status
) {
  const updatedBookingData = [];

  bookingData.forEach(booking => {
    // eslint-disable-next-line no-param-reassign
    if (booking._id === bookingId) booking.status = status;

    updatedBookingData.push(booking);
  });

  setBookingData(updatedBookingData);
  setBookingData(bookings => bookings);
}

export function updateBookingStateOnMake(
  setUserBookings,
  userBookings,
  booking,
  currentUser,
  currentRoom
) {
  if (userBookings) {
    const updatedUserBookings = { ...userBookings };

    booking.forEach(b => {
      const updatedBooking = b;
      updatedBooking.user = currentUser.id;
      updatedBooking.roomId = currentRoom;

      updatedUserBookings.current.push(updatedBooking);
    });

    setUserBookings(updatedUserBookings);
  }
}
