import React from 'react';
import * as Styled from './ReservationTypeNav.styles';
import Icon from '../Icon/Icon';

const ReservationTypeNav = () => (
  <Styled.ReservationTypeNav>
    <Styled.ReservationTypeNavLink to="/" exact>
      <Icon icon="frequency" size={20} className="nav-icon" />
      Zasoby
    </Styled.ReservationTypeNavLink>

    <Styled.ReservationTypeNavLink to="/attendance-list">
      <Icon icon="attendance" size={20} className="nav-icon" />
      Obecności
    </Styled.ReservationTypeNavLink>

    <Styled.ReservationTypeNavLink to="/home-office">
      <Icon icon="attendance" size={20} className="nav-icon" />
      Home office
    </Styled.ReservationTypeNavLink>
  </Styled.ReservationTypeNav>
);

export default ReservationTypeNav;
