import React, { useState } from 'react';
import Layout from '../../layouts/Layout/Layout';
import Content from '../../layouts/Content';
import Sidebar from '../../layouts/Sidebar';
import Box from '../../layouts/Box';
import ReportsForm from './ReportsForm';
import AttendanceTable from './AttendanceTable';

const HomeOfficeReports = props => {
  const [fetchedData, setFetchedData] = useState(null);
  const { currentUser, navToggler } = props;

  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title="Raport home office"
    >
      <Sidebar>
        <Box header="Wygeneruj raport home office">
          <ReportsForm setFetchedData={setFetchedData} />
        </Box>
      </Sidebar>
      <Content>
        <Box header="Lista osób pracujących z domu">
          {/* eslint-disable-next-line no-nested-ternary */}
          {!fetchedData ? (
            <p>Wypełnij formularz, aby wyświetlić raport.</p>
          ) : fetchedData.length !== 0 ? (
            <AttendanceTable fetchedData={fetchedData} />
          ) : (
            <p>Brak wyników dla tego zakresu dat.</p>
          )}
        </Box>
      </Content>
    </Layout>
  );
};

export default HomeOfficeReports;
