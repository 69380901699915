import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Dashboard from './Dashboard';
import AttendanceList from './AttendanceList';
import CreateBooking from './CreateBooking';
import { roles } from '../config/roles';
import PendingBookings from './PendingBookings';
import Reports from './Reports';
import MyBookings from './MyBookings';
import Account from './Account';
import ErrorPage from './ErrorPage';
import Styleguide from './Styleguide';
import GarageReports from './GarageReports';
import HomeOffice from './HomeOffice';
import HomeOfficeReports from './HomeOfficeReports';

const Routes = props => {
  const history = useHistory();
  const location = useLocation();

  return (
    <TransitionGroup className="view-animation-wrapper">
      <CSSTransition
        timeout={300}
        classNames="view-animation"
        key={location.key}
      >
        <Switch location={location}>
          <Route
            path="/"
            exact
            render={() => (
              <Dashboard
                currentUser={props.currentUser}
                navToggler={props.navToggler}
                // Calendar
                setCalendarDate={props.setCalendarDate}
                calendarDate={props.calendarDate}
                // Rooms list
                roomData={props.roomData}
                setRoomData={props.setRoomData}
                bookingData={props.bookingData}
                setBookingData={props.setBookingData}
                eventsData={props.eventsData}
                setEventsData={props.setEventsData}
                setSelectedBooking={props.setSelectedBooking}
                onSetRoom={props.onSetRoom}
                setCurrentHour={props.setCurrentHour}
                setCurrentUser={props.setCurrentUser}
                currentDataDate={props.currentDataDate}
                setCurrentDataDate={props.setCurrentDataDate}
                toggledView={props.toggledView}
                setToggledView={props.setToggledView}
                // Booking modal
                selectedBooking={props.selectedBooking}
                onCloseBooking={props.onCloseBooking}
                onDeleteBooking={props.onDeleteBooking}
              />
            )}
          />

          <Route
            path="/attendance-list"
            exact
            render={() => (
              <AttendanceList
                currentUser={props.currentUser}
                navToggler={props.navToggler}
                // Calendar
                setCalendarDate={props.setCalendarDate}
                calendarDate={props.calendarDate}
                // Attendance
                setAttendanceData={props.setAttendanceData}
                attendanceData={props.attendanceData}
                setCurrentAttendanceDate={props.setCurrentAttendanceDate}
                currentAttendanceDate={props.currentAttendanceDate}
                // for updates on delete
                bookingData={props.bookingData}
                setBookingData={props.setBookingData}
                userBookings={props.userBookings}
                setUserBookings={props.setUserBookings}
              />
            )}
          />

          <Route
            path="/home-office"
            exact
            render={() => (
              <HomeOffice
                currentUser={props.currentUser}
                navToggler={props.navToggler}
                // Calendar
                setCalendarDate={props.setCalendarDate}
                calendarDate={props.calendarDate}
                // Home Office
                homeOfficeData={props.homeOfficeData}
                setHomeOfficeData={props.setHomeOfficeData}
                currentHomeOfficeDate={props.currentHomeOfficeDate}
                setCurrentHomeOfficeDate={props.setCurrentHomeOfficeDate}
              />
            )}
          />

          <Route
            path="/createbooking"
            exact
            render={() =>
              props.currentRoom ? (
                <CreateBooking
                  currentUser={props.currentUser}
                  navToggler={props.navToggler}
                  currentRoom={props.currentRoom}
                  currentHour={props.currentHour}
                  bookingData={props.bookingData}
                  setBookingData={props.setBookingData}
                  eventsData={props.eventsData}
                  setEventsData={props.setEventsData}
                  userBookings={props.userBookings}
                  setUserBookings={props.setUserBookings}
                  calendarDate={props.calendarDate}
                  setCalendarDate={props.setCalendarDate}
                  setSelectedBooking={props.setSelectedBooking}
                  userRoles={props.userRoles}
                  // Booking modal
                  selectedBooking={props.selectedBooking}
                  onCloseBooking={props.onCloseBooking}
                  onDeleteBooking={props.onDeleteBooking}
                  currentDataDate={props.currentDataDate}
                />
              ) : (
                // history.push('/')
                <Redirect to="/" />
              )
            }
          />

          <Route
            path={[
              '/pendingbookings',
              '/pendingbookings/:bookingId/:bookingStatus'
            ]}
            exact
            render={() => (
              <PendingBookings
                currentUser={props.currentUser}
                navToggler={props.navToggler}
                bookingData={props.bookingData}
                setBookingData={props.setBookingData}
                currentDataDate={props.currentDataDate}
                setCurrentDataDate={props.setCurrentDataDate}
              />
            )}
          />

          <Route
            path="/reports"
            exact
            render={() =>
              props.currentUser.role === roles.admin ? (
                <Reports
                  currentUser={props.currentUser}
                  navToggler={props.navToggler}
                />
              ) : (
                history.push('/')
              )
            }
          />

          <Route
            path="/garage-reports"
            exact
            render={() =>
              props.currentUser.role === roles.admin ? (
                <GarageReports
                  currentUser={props.currentUser}
                  navToggler={props.navToggler}
                />
              ) : (
                history.push('/')
              )
            }
          />

          <Route
            path="/homeoffice-reports"
            exact
            render={() =>
              props.currentUser.role === roles.admin ? (
                <HomeOfficeReports
                  currentUser={props.currentUser}
                  navToggler={props.navToggler}
                />
              ) : (
                history.push('/')
              )
            }
          />

          <Route
            path="/mybookings"
            exact
            render={() => (
              <MyBookings
                currentUser={props.currentUser}
                navToggler={props.navToggler}
                bookingData={props.bookingData}
                userBookings={props.userBookings}
                setUserBookings={props.setUserBookings}
                onDeleteBooking={props.onDeleteBooking}
                currentDataDate={props.currentDataDate}
              />
            )}
          />

          <Route
            path="/account"
            exact
            render={() => (
              <Account
                currentUser={props.currentUser}
                navToggler={props.navToggler}
              />
            )}
          />

          <Route
            path="/styleguide"
            exact
            render={() => (
              <Styleguide
                currentUser={props.currentUser}
                navToggler={props.navToggler}
              />
            )}
          />

          <Route
            render={() => (
              <ErrorPage
                currentUser={props.currentUser}
                navToggler={props.navToggler}
              />
            )}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Routes;
